import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { css } from "glamor";
import g from "glamorous";
import Img from "gatsby-image";
import { Row, Col } from "glamorous-grid";
import { FaGraduationCap } from "react-icons/fa";

export default () => (
  <StaticQuery
    query={graphql`
      query portrait {
        file(
          relativePath: {
            eq: "images/dr-cedric-kron-chirurgie-esthetique-paris.jpg"
          }
        ) {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <BioWrapper>
        <Row>
          <Col span={{ xs: 12 / 12 }}>
            <H2>Avant-Propos</H2>
          </Col>
          <Col span={{ xs: 12 / 12, lg: 40 / 100 }}>
            {" "}
            <Img
              fluid={data.file.childImageSharp.fluid}
              css={{
                "@media(max-width: 767px)": {
                  marginBottom: `.8em`,
                },
              }}
              alt="Dr Cédric Kron, chirurgien esthétique"
            />
            <Link
              {...cssButton}
              to="/chirurgien-esthetique-paris/"
              title="Titres et diplômes du Dr Kron, chirurgien esthétique à Paris"
            >
              <FaGraduationCap
                css={{
                  marginRight: `10px`,
                  marginBottom: `3px`,
                  transform: `translateY(5px)`,
                }}
              />
              Voir ses titres et diplômes
            </Link>
          </Col>
          <Col span={{ xs: 12 / 12, lg: 60 / 100 }}>
            <H3>
              Chirurgien esthétique qualifié en Chirurgie Esthétique, Plastique
              et Reconstructrice exerçant à Paris.
            </H3>

            <p>
              Je pratique l’ensemble des interventions que couvre ma
              spécialité : la <Link to="/seins/">chirurgie des seins</Link> (
              <Link to="/seins/protheses-mammaires/">
                augmentation mammaire par prothèses
              </Link>
              ,{" "}
              <Link to="/seins/rajeunissement-mammaire-correction-ptose/">
                correction de ptôse
              </Link>
              ), du <Link to="/visage/">visage</Link> (
              <Link to="/lifting/">lifting</Link>,
              <Link to="/regard/blepharoplastie-chirurgie-paupieres/">
                blépharoplastie
              </Link>
              , <Link to="/oreilles/oreilles-decollees/">otoplastie</Link>) et
              de le <Link to="/silhouette/">silhouette</Link> (
              <Link to="/silhouette/plastie-abdominale/">
                plastie abdominale
              </Link>
              , <Link to="/silhouette/lipoaspiration/">lipoaspiration</Link>,{" "}
              <Link to="/silhouette/bodylift/">bodylift</Link>
              ).
            </p>

            <p>
              Je pratique également l'ensemble des traitements de{" "}
              <strong>médecine esthétique</strong> qui ont fait preuve de leur{" "}
              <strong>sécurité</strong> et de leur <strong>efficacité</strong> :{" "}
              <Link to="/botox/">injections de botox</Link> et d’
              <Link to="/acide-hyaluronique/">acide hyaluronique</Link>,{" "}
              traitements de réjuvénation de la peau (
              <Link to="/peeling/">peelings</Link>,{" "}
              <Link to="/peau/laser-icon-correction-imperfections/">laser</Link>
              , <Link to="/visage/ellanse/">Ellansé®</Link> ou{" "}
              <Link to="/visage/hydrafacial/">HydraFacial</Link>). Je propose
              également des traitements non-invasif de{" "}
              <Link to="/suppression-des-graisses/">
                suppression des graisses
              </Link>
              , de la{" "}
              <Link to="/silhouette/keybody-cellulite/">cellulite </Link>
              et de sculpting musculaire (
              <Link to="/silhouette/cryolipolyse-coolsculpting/">
                CoolSculpting
              </Link>
              , <Link to="/silhouette/emsculpt/">EMSculpt</Link> et{" "}
              <Link to="/xbody-sculpter-raffermir-son-corps/">XBody</Link>) dans
              le cadre du protocole{" "}
              <Link to="/silhouette/keybody-lipo/">Keybody-Lipo</Link>.
            </p>
          </Col>

          <Col>
            <p>
              J'exerce mon activité de chirurgien esthétique exclusivement en
              secteur privé, ce qui me permet de prendre en charge mes patients
              24h/24 quand cela est nécessaire. Je consulte dans mon propre{" "}
              <Link to="/centre-esthetique-paris/">espace de consultation</Link>{" "}
              situé à Paris (17e) dédié à une{" "}
              <strong>prise en charge globale «&nbsp;sur mesure&nbsp;»</strong>{" "}
              des traitements esthétiques du visage et du corps.
            </p>

            <p>
              Les opérations que je pratique ont uniquement lieux dans des{" "}
              <strong>cliniques agréées</strong> pour la chirurgie esthétique,
              plastique et reconstructrice à Paris, notamment la Nouvelle
              Clinique d'Esthétique des Champs-Elysées (Paris 8e).
            </p>

            <p>
              Qu'il s'agisse de chirurgie esthétique ou de médecine esthétique,
              je considère qu’une intervention de chirurgie esthétique réussie
              est une intervention qui permet d’obtenir un{" "}
              <strong>résultat naturel</strong>, durable et harmonieux avec
              l’ensemble du corps. Il me parait indispensable de parfaitement
              comprendre le souhait du patient et de s’y adapter, un des écueils
              de la chirurgie esthétique étant de tomber dans des stéréotypes
              qui sont pour moi antinomiques avec l’idée de beauté et de
              séduction. Je cherche également à utiliser des techniques
              chirurgicales permettant une{" "}
              <strong>
                reprise des activités de la vie quotidienne la plus rapide
                possible
              </strong>{" "}
              et des suites opératoires les plus simples possibles.
            </p>

            <p
              css={{
                textAlign: `right`,
                fontSize: `1.1em`,
                color: `#3c4d68`,
                marginRight: `5em`,
                fontWeight: `500`,
                "@media(max-width: 767px)": {
                  marginRight: `0.5em`,
                },
              }}
            >
              Dr Cédric Kron
            </p>
          </Col>
        </Row>
      </BioWrapper>
    )}
  />
);

const BioWrapper = g.section({
  backgroundColor: `white`,
  marginBottom: `3em`,
  borderRadius: `6px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  padding: `1em 1.25em 1.5em 1.5em`,
  "@media(max-width: 767px)": {
    padding: `.8em`,
  },
});

const H2 = g.h2({
  position: `relative`,
  fontSize: `32px`,
  fontWeight: `500`,
  marginTop: `0.2em`,
  marginBottom: `.5em`,
  "&::after": {
    content: ` `,
    position: `absolute`,
    top: `54%`,
    left: `215px`,
    border: `.063em solid #3c4d68`,
    display: `inline-block`,
    width: `840px`,
  },
  "@media(max-width: 767px)": {
    margin: `.25em 0 .6em`,
    "&::after": {
      content: `none`,
    },
  },
  "@media(max-width: 1100px)": {
    "&::after": {
      content: `none`,
    },
  },
});

const H3 = g.h3({
  color: `#1f1f1f`,
  margin: `0 0 0.2em`,
  font: `500 16px/26px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
});

const cssButton = css({
  color: `white`,
  backgroundColor: `#3c4d68`,
  border: `1px solid #3c4d68`,
  padding: `.4em 1.5em`,
  display: `block`,
  margin: `1em 0 12px`,
  borderRadius: `5px`,
  textAlign: `center`,
  fontWeight: `500`,
  fontSize: `15px`,
  minWidth: `215px`,
  float: `right`,
  textDecoration: `none`,
  letterSpacing: `.03em`,
  transition: `all 0.3s ease-in-out 0s`,
  ":hover": {
    backgroundColor: `white`,
    color: `#3c4d68`,
    transition: `all 0.3s ease-in-out 0s`,
  },
});
